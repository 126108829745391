<template>
    <div class="base">
        <Header @handleClick="handleClick" :currentTab="currentTab" />
        <div style="width: 100%;">
            <el-form ref="base_form" :model="formData" label-width="80px" :rules="rules">
                <el-form-item label="流程名称" prop="processName">
                    <el-input v-model="formData.processName" maxlength="20" placeholder="输入流程名称"></el-input>
                </el-form-item>
                <el-form-item label="选择分组" prop="processGroup">
                    <el-select v-model="formData.processGroup" placeholder="请选择" class="mr15">
                        <el-option v-for="item in processGroupTypeList" :key="item.codeValue" :label="item.codeLabel"
                            :value="item.codeValue">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="流程类型" prop="processType">
                    <el-select v-model="formData.processType" placeholder="请选择类型" class="mr15">
                        <el-option v-for="item in processTypeList" :key="item.codeValue" :label="item.codeLabel"
                            :value="item.codeValue">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="流程说明">
                    <el-input type="textarea" v-model="formData.remark" :autosize="{ minRows: 4, maxRows: 10 }"
                        placeholder="输入200字以内内容" maxlength='200'></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import Header from './header.vue';
import { addProcess, updateProcess, queryProcessGroupTypeList, queryProcessTypeList, queryProcessDetail } from "@/api/examine/process.js"
export default {
    components: {
        Header
    },
    beforeRouteLeave(to, form, next) {
        //离开该路由之前想进行的操作
        window.localStorage.setItem('processformData', JSON.stringify(this.formData));
        next()
    },
    data() {
        return {
            currentTab: 0,
            formData: {
                processId: ''
            },
            rules: {
                processName: [
                    { required: true, message: '请输入流程名称', trigger: 'blur' }
                ],
                processGroup: [
                    { required: true, message: '请选择分组', trigger: 'change' }
                ],
                processType: [
                    { required: true, message: '请选择类型', trigger: 'change' }
                ],
            },
            processGroupTypeList: [],
            processTypeList: []
        }
    },
    created() {
        this.initData();
    },
    methods: {
        initData() {
            // 查询选择分组列表
            queryProcessGroupTypeList().then(res => {
                if (res.isSuccess == 'yes') {
                    this.processGroupTypeList = res.data;
                } else {
                    this.$message.error(res.message)
                }
            })
            // 查询选择类型列表
            queryProcessTypeList().then(res => {
                if (res.isSuccess == 'yes') {
                    this.processTypeList = res.data;
                } else {
                    this.$message.error(res.message)
                }
            })
            if (this.$route.query.processId) {
                this.getProcessDetail(this.$route.query.processId);
            }

        },
        // 获取流程结构
        getProcessDetail(id) {
            let params = {
                processId: id,
            };
            queryProcessDetail(params)
                .then((res) => {
                    this.formData = res.data;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        // 新增流程
        addProcess() {
            addProcess(this.formData).then(res => {
                if (res.isSuccess == 'yes') {
                    this.$router.push({ path: '/examine/condition', query: { processId: res.data } });
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 修改流程
        updateProcess() {
            updateProcess(this.formData).then(res => {
                if (res.isSuccess == 'yes') {
                    this.$router.push({ path: '/examine/condition', query: { processId: this.formData.processId } });
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 点击下一步
        handleClick() {
            this.$refs['base_form'].validate((valid) => {
                if (valid) {
                    if (this.formData.processId) {
                        this.updateProcess();
                    } else {
                        this.addProcess();
                    }
                } else {
                    return false;
                }
            });
        }
    }
}
</script>
<style scoped lang="scss">
.base {
    .el-form{
        display: block;
    }
    padding: 0.15rem;
}
</style>